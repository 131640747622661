import React from "react"

const EnvelopeIcon = ({ fill }) => (
  <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 0c.83 0 1.5.67 1.5 1.5v9c0 .83-.67 1.5-1.5 1.5h-13A1.5 1.5 0 010 10.5v-9C0 .67.67 0 1.5 0h13zm-13 1a.5.5 0 00-.5.5v1.3c.68.57 1.66 1.37 4.7 3.78C6.24 7 7.28 8.01 8 8c.72.01 1.77-1 2.3-1.42 3.04-2.41 4.02-3.2 4.7-3.79V1.5a.5.5 0 00-.5-.5h-13zm13 10a.5.5 0 00.5-.5V4.1l-4.08 3.27C10.26 7.89 9.14 9 8 9 6.86 9 5.72 7.88 5.08 7.37A370.05 370.05 0 011 4.09v6.41c0 .27.23.5.5.5h13z"
      fill={fill}
    />
  </svg>
)

export default EnvelopeIcon
